import Diff from './base';
import { generateOptions } from '../util/params';

// Based on https://en.wikipedia.org/wiki/Latin_script_in_Unicode
//
// Ranges and exceptions:
// Latin-1 Supplement, 0080–00FF
//  - U+00D7  × Multiplication sign
//  - U+00F7  ÷ Division sign
// Latin Extended-A, 0100–017F
// Latin Extended-B, 0180–024F
// IPA Extensions, 0250–02AF
// Spacing Modifier Letters, 02B0–02FF
//  - U+02C7  ˇ &#711;  Caron
//  - U+02D8  ˘ &#728;  Breve
//  - U+02D9  ˙ &#729;  Dot Above
//  - U+02DA  ˚ &#730;  Ring Above
//  - U+02DB  ˛ &#731;  Ogonek
//  - U+02DC  ˜ &#732;  Small Tilde
//  - U+02DD  ˝ &#733;  Double Acute Accent
// Latin Extended Additional, 1E00–1EFF
const extendedWordChars = /^[a-zA-Z\u{C0}-\u{FF}\u{D8}-\u{F6}\u{F8}-\u{2C6}\u{2C8}-\u{2D7}\u{2DE}-\u{2FF}\u{1E00}-\u{1EFF}]+$/u;

const reWhitespace = /\S/;

export const wordDiff = new Diff();
wordDiff.equals = function (left, right) {
    if (this.options.ignoreCase) {
        left = left.toLowerCase();
        right = right.toLowerCase();
    }
    return left === right || (this.options.ignoreWhitespace && !reWhitespace.test(left) && !reWhitespace.test(right));
};
wordDiff.tokenize = function (iStr) {

    let tokens = [];
    let iLength = iStr.length
    let idx = 0

    let tStr = ''
    let oStr = ''
    let suku = '';
    let K = '[\uA984-\uA9B2]';
    let P = '[꧀]';
    let R = '꧀ꦮ|꧀ꦭ|[\uA9BE-\uA9BF]';
    let S = '[\uA980-\uA983]';
    let N = '[꦳]';
    let V1 = '[\uA9B4-\uA9BD]';
    let V2 = '[ꦴꦵ]';
    let SYLLABLE = `^(${K})(${N})?(${P})?(${K})?(${N})?(${R})?(${V1})?(${V2})?(${S})?`;

    while (idx < iLength) {
        suku = ""
        let [m, mK1, mN1, mP, mK2, mN2, mR, mV1, mV2, mS] = iStr.match(SYLLABLE) || [null]
        // console.log([m, mK1, mN1, mP, mK2, mN2, mR, mV1, mV2, mS]);
        if (m !== null) {
            if (mP) {
                suku = m;
            } else if (!mK2) {
                suku = mK1 + (mR || '') + (mV1 || '') + (mV2 || '') + (mS || '')
            } else {
                suku = mK1
            }
        } else {
            suku = iStr[0];
        }
        tokens.push(suku)
        iStr = iStr.substr(suku.length)
        idx += suku.length
    }
    return tokens;
    //   // All whitespace symbols except newline group into one token, each newline - in separate token
    //   let tokens = value.split(/([^\S\r\n]+|[()[\]{}'"\r\n]|\b)/);

    //   // Join the boundary splits that we do not consider to be boundaries. This is primarily the extended Latin character set.
    //   for (let i = 0; i < tokens.length - 1; i++) {
    //     // If we have an empty string in the next field and we have only word chars before and after, merge
    //     if (!tokens[i + 1] && tokens[i + 2]
    //           && extendedWordChars.test(tokens[i])
    //           && extendedWordChars.test(tokens[i + 2])) {
    //       tokens[i] += tokens[i + 2];
    //       tokens.splice(i + 1, 2);
    //       i--;
    //     }
    //   }

    //   return tokens;
};

export function diffWords(oldStr, newStr, options) {
    options = generateOptions(options, { ignoreWhitespace: true });
    return wordDiff.diff(oldStr, newStr, options);
}

export function diffWordsWithSpace(oldStr, newStr, options) {
    return wordDiff.diff(oldStr, newStr, options);
}
