import React, { useState } from 'react'
import axios from "axios";
import {
    Container,
    Row,
    Col,
    FormGroup,
    Input,
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert
} from "reactstrap";

function ModalAuth({ isOpen, toggle }) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [msg, setMsg] = useState('');
    const [msg2, setMsg2] = useState('');
    const [type, setType] = useState(0);
    const TYPE_INTRO = 0;
    const TYPE_REGISTER = 1;
    const TYPE_LOGIN = 2;

    const Register = async (e) => {
        e.preventDefault();
        try {
            await axios.post('/user/register', {
                name: name,
                email: email,
                password: password,
                confPassword: confPassword
            });
            setMsg2("Akun berhasil dibuat, silakan Login.");
            setType(TYPE_LOGIN);
        } catch (error) {
            if (error.response) {
                if (typeof error.response.data.msg === 'object' && error.response.data.msg !== null) {
                    let msg = "";
                    for (const item in error.response.data.msg) {
                        msg += (error.response.data.msg[item] + "; \r\n");
                    }
                    setMsg(msg);
                } else {
                    setMsg(error.response.data.msg);
                }
            }
        }
    }

    const Login = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post('/user/login', {
                email: email,
                password: password
            });
            localStorage.setItem('segajabung_token', res.data.access_token);
            toggle();
        } catch (error) {
            if (error.response) {
                if (typeof error.response.data.msg === 'object' && error.response.data.msg !== null) {
                    let msg = "";
                    for (const item in error.response.data.msg) {
                        msg += (error.response.data.msg[item] + "; \r\n");
                    }
                    setMsg(msg);
                } else {
                    setMsg(error.response.data.msg);
                }
            }
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="sj-modal" onOpened={() => {
            setType(TYPE_INTRO);
            setMsg('');
            setMsg2('');
        }}>
            <ModalHeader toggle={toggle}>Fitur Khusus Akun Premium</ModalHeader>
            <ModalBody>
                {type == TYPE_INTRO && (
                    <>
                        <div>
                            <p>Fitur <b>Cek Skor</b> untuk soal ini hanya bisa diakses oleh member premium Komunitas Segajabung. Untuk mendaftar menjadi akun premium, hanya dengan cukup mendaftar dan berdonasi minimal <b>Rp 50.000</b> dan kamu akan dapat mengakses semua fungsi pada aplikasi ini selama setahun penuh.</p>
                            <button style={styles.bottomButton} onClick={() => setType(TYPE_REGISTER)}>Daftar Sekarang</button>
                            <br />
                            <br />
                            <div className="field mb-2">
                                Sudah punya akun? <a href="javascript:void(0)" onClick={() => setType(TYPE_LOGIN)}>Masuk Sekarang</a>
                            </div>
                            <p>
                                <b>Mangapa pelu donasi?</b><br />
                                Komunitas Segajabung merupakan organisasi nirlaba sehingga membutuhkan pendanaan untuk pemeliharaan website dan untuk mengadakan kegiatan-kegiatan pelatihan aksara Jawa.
                            </p>
                        </div>
                    </>
                )}
                {type == TYPE_REGISTER && (
                    <>
                        <form onSubmit={Register} className="box">
                            {msg &&
                                <Alert color='danger'>{msg}</Alert>
                            }
                            <div className="field mb-3">
                                <label className="label">Nama</label>
                                <div className="controls">
                                    <input type="text" className="form-control form-control-sj" placeholder="Nama"
                                        value={name} onChange={(e) => setName(e.target.value)} required />
                                </div>
                            </div>
                            <div className="field mb-3">
                                <label className="label">Email</label>
                                <div className="controls">
                                    <input type="text" className="form-control form-control-sj" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                </div>
                            </div>
                            <div className="field mb-3">
                                <label className="label">Password</label>
                                <div className="controls">
                                    <input type="password" className="form-control form-control-sj" placeholder="******" value={password} onChange={(e) => setPassword(e.target.value)} required />
                                </div>
                            </div>
                            <div className="field mb-4">
                                Sudah punya akun? <a href="javascript:void(0)" onClick={() => setType(TYPE_LOGIN)}>Masuk Sekarang</a>
                            </div>
                            <div className="field">
                                <button style={styles.bottomButton}>Daftar Sekarang</button>
                            </div>
                        </form>
                    </>
                )}
                {type == TYPE_LOGIN && (
                    <>
                        <form onSubmit={Login} className="box">
                            {msg &&
                                <Alert color='danger'>{msg}</Alert>
                            }
                            {msg2 &&
                                <Alert>{msg2}</Alert>
                            }
                            <div className="field mb-3">
                                <label className="label">Email</label>
                                <div className="controls">
                                    <input type="text" className="form-control form-control-sj" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                </div>
                            </div>
                            <div className="field mb-3">
                                <label className="label">Password</label>
                                <div className="controls">
                                    <input type="password" className="form-control form-control-sj" placeholder="******" value={password} onChange={(e) => setPassword(e.target.value)} required />
                                </div>
                            </div>
                            <div className="field mb-4">
                                Belum punya akun? <a href="javascript:void(0)" onClick={() => setType(TYPE_REGISTER)}>Daftar Sekarang</a>
                            </div>
                            <div className="field">
                                <button style={styles.bottomButton}>Masuk Sekarang</button>
                            </div>
                        </form>
                    </>
                )}
            </ModalBody>
        </Modal>
    );
}

const styles = {
    bottomButton: {
        background: "rgb(236,100,85)",
        background: "linear-gradient(180deg, rgba(236,100,85,1) 25%, rgba(222,53,41,1) 67%)",
        border: "5px solid #9c1507",
        borderRadius: "20px",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "-23px",
        paddingLeft: "10px",
        paddingRight: "10px",
        textAlign: "center",
        color: "#fafafa",
        textShadow: "-1px -1px 0 #9c1507, 1px -1px 0 #9c1507, -1px 1px 0 #9c1507, 1px 1px 0 #9c1507",
        fontFamily: "'Comfortaa'"
    }
}

export default ModalAuth;