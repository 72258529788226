import styled from "styled-components";
import {
    Container,
    Row,
    Col,
    FormGroup,
    Input,
    Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import Timer from 'react-timer-wrapper';
import Timecode from 'react-timecode';
import React, { useState } from 'react';
import { useEffect } from "react";
import gancaran from "../../soal/gancaran";
import tembang from "../../soal/tembang";
import { Link, useNavigation } from "react-router-dom";
import { diffWords } from "../../lib/diff";
import ModalAuth from "../../components/molecules/Modal/ModalAuth";
import axios from "axios";

const styles = {
    colContainer: {
        height: 'calc(100vh - 50px)',
        background: 'radial-gradient(circle, rgba(247,232,175,1) 22%, rgba(222,209,158,1) 50%)', border: "14px groove #D7A56D", borderRadius: "20px",
        position: "relative"
    },
    colTitle: {
        background: "#A7E24D",
        border: "5px solid #3F8D44",
        borderRadius: "20px",
        width: "200px",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "-23px",
        paddingLeft: "10px",
        paddingRight: "10px",
        textAlign: "center",
        color: "#fafafa",
        textShadow: "-1px -1px 0 #3F8D44, 1px -1px 0 #3F8D44, -1px 1px 0 #3F8D44, 1px 1px 0 #3F8D44",
        fontFamily: "'Comfortaa'"
    },
    bottomButton: {
        background: "rgb(236,100,85)",
        background: "linear-gradient(180deg, rgba(236,100,85,1) 25%, rgba(222,53,41,1) 67%)",
        border: "5px solid #9c1507",
        borderRadius: "20px",
        width: "120px",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "-23px",
        paddingLeft: "10px",
        paddingRight: "10px",
        textAlign: "center",
        color: "#fafafa",
        textShadow: "-1px -1px 0 #9c1507, 1px -1px 0 #9c1507, -1px 1px 0 #9c1507, 1px 1px 0 #9c1507",
        fontFamily: "'Comfortaa'",
        position: "absolute",
        bottom: "-20px",
        right: "30px",
        cursor: "pointer"
    },
    timer: {
        fontFamily: "'Orbitron', sans-serif",
        marginLeft: "auto",
        marginRight: "auto",
        width: "100px",
        textAlign: "center",
        background: "#B45B20",
        marginBottom: "20px",
        color: '#FAFAFA',
        fontSize: "20px"
    },
    backButton: {
        position: "absolute",
        left: "50px",
        top: "10px"
    }
}

function Converter({ index }) {
    const [kunci, setKunci] = useState("");
    const [jawaban, setJawaban] = useState("");
    const [skor, setSkor] = useState(0);
    const [salah, setSalah] = useState(0);
    const [result, setResult] = useState([]);

    useEffect(() => {
        if (!kunci) {
            return;
        }
        const rep = {
            "꧈꧃": "꧄",
            "꧈꧄": "꧄",
            "꧈꧅": "꧄",
            "꧉꧃": "꧄",
            "꧉꧄": "꧄",
            "꧉꧅": "꧄"
        };
        var jawaban2 = jawaban;
        for (const [key, value] of Object.entries(rep)) {
            jawaban2 = jawaban2.replace(key, value);
        }
        function countDiff(k, j) {
            return diffWords(k, j, {
                comparator: (a, b) => {
                    if (a === b) {
                        return true;
                    }
                    const arrEqual = {
                        "ꦏ": "ꦑ",
                        "ꦒ": "ꦓ",
                        "ꦠ": "ꦡ",
                        "ꦤ": "ꦟ",
                        "ꦥ": "ꦦ",
                        "ꦧ": "ꦨ",
                        "ꦱ": "ꦯ",
                        "ꦲ": "ꦄ",
                        "ꦲꦶ": "ꦆ",
                        "ꦲꦸ": "ꦈ",
                        "ꦲꦺ": "ꦌ",
                        "ꦲꦺꦴ": "ꦎ",
                        "꧃": "꧄",
                        "꧅": "꧄",
                        "​": " "
                    };
                    for (const [key, value] of Object.entries(arrEqual)) {
                        const c = a.replace(key, value);
                        if (c === b) return true;
                    }
                    return false;
                }
            });
        }
        const diff = countDiff(kunci, jawaban2);
        console.log(diff);
        setResult(diff);
        let jmlKarakter = 0;
        let cRemoved = 0;
        let cAdded = 0;
        let jmlPerbedaan = 0;
        diff.map((val, idx) => {
            if (!val.hasOwnProperty("added")) {
                jmlKarakter += val.count;
                jmlPerbedaan += Math.max(cRemoved, cAdded);
                cRemoved = 0;
                cAdded = 0;
                return;
            }
            if (val.removed) {
                cRemoved = val.count;
                jmlKarakter += val.count;
                if (idx == (diff.length - 1)) {
                    jmlPerbedaan += cRemoved;
                }
            }
            if (val.added) {
                cAdded = val.count;
                if (idx == (diff.length - 1)) {
                    jmlPerbedaan += Math.max(cRemoved, cAdded);
                }
            }

        })
        setSkor(((jmlKarakter - jmlPerbedaan) / jmlKarakter * 100).toFixed(2));
        setSalah(jmlPerbedaan);
    }, [jawaban])

    return (
        <section>
            <div style={{ height: "20px" }}></div>
            <Container>
                <Row>
                    <Col sm="4">
                        <div style={styles.colContainer}>
                            <div style={styles.colTitle}>
                                Kunci Jawaban
                            </div>
                            <Input
                                style={{
                                    overflow: "auto", left: "0", marginTop: "10px", position: "absolute", height: "95%",
                                    background: "transparent",
                                    fontFamily: "'nyk Ngayogyan Jejeg'",
                                    lineBreak: "anywhere",
                                    lineHeight: "2.3",
                                    border: "none"
                                }}
                                type="textarea"
                                spellCheck={false}
                                onChange={e => { setKunci(e.target.value) }}
                            />
                        </div>
                    </Col>
                    <Col sm="4">
                        <div style={styles.colContainer}>
                            <div style={styles.colTitle}>
                                Jawaban Peserta
                            </div>
                            <Input
                                style={{
                                    overflow: "auto", left: "0", marginTop: "10px", position: "absolute", height: "95%",
                                    background: "transparent",
                                    fontFamily: "'nyk Ngayogyan Jejeg'",
                                    lineBreak: "anywhere",
                                    lineHeight: "2.3",
                                    border: "none"
                                }}
                                type="textarea"
                                spellCheck={false}
                                onChange={e => { setJawaban(e.target.value) }}
                            />
                        </div>
                    </Col>
                    <Col sm="4">
                        <div style={{ ...styles.colContainer, height: "80px", marginBottom: "20px" }}>
                            <div style={styles.colTitle}>
                                Skor
                            </div>
                            <div style={{ margin: "10px", textAlign: "center" }}>
                                Skor: {skor} | Kesalahan: {salah}
                            </div>
                        </div>
                        <div style={{ ...styles.colContainer, height: "calc(100vh - 150px)" }}>
                            <div style={styles.colTitle}>
                                Rincian
                            </div>
                            <div style={{ overflow: "auto", margin: "10px", position: "absolute", height: "90%", fontFamily: "'nyk Ngayogyan Jejeg'", lineHeight: "2", lineBreak: "anywhere" }}>
                                <p className="cek-kesalahan">
                                    {
                                        result.map(val => {
                                            val.value = val.value.replace("\n", "\r\n");
                                            return (
                                                <>
                                                    {!val.added && !val.removed && (val.value).split("\n").map(v => {
                                                        return (
                                                            <>
                                                                {v}
                                                                {(val.value).split("\n").length > 1 && (<><br /></>)}
                                                            </>
                                                        )
                                                    })
                                                    }
                                                    {val.added && (val.value).split("\n").map(v => {
                                                        return (
                                                            <ins>
                                                                {v}
                                                                {(val.value).split("\n").length > 1 && (<><br /></>)}
                                                            </ins>
                                                        )
                                                    })
                                                    }

                                                    {val.removed && (val.value).split("\n").map(v => {
                                                        console.log("lenth", (val.value).split("\n").length);
                                                        return (
                                                            <del>
                                                                {v}
                                                                {(val.value).split("\n").length > 1 && (<><br /></>)}
                                                            </del>
                                                        )
                                                    })
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section >
    );
}

export default Converter;
