import { Fragment } from "react";
import Converter from "./Converter";
import { Container } from "reactstrap";
import { useLocation } from "react-router-dom";

function Home() {
    return (
        <Fragment>
            {/* <Jumbotron /> */}
            <Converter />
            <Container>
            </Container>
        </Fragment>
    );
}

export default Home;
